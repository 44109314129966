@import url("https://fonts.googleapis.com/css2?family=Bungee&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,800;1,100;1,200;1,300;1,500;1,600;1,800&display=swap");

body {
  height: 100vh;
  /* background: url(./assets/svg/GRIDHOME.svg) no-repeat; */
  background-color: #07001c;

  /* // -webkit-background-size: cover; */
  /* // -moz-background-size: cover; */
  /* // -o-background-size: cover; */
  /* // background-size: cover; */
  /* // padding: 0; */
  font-family: "Urbanist", sans-serif;
  font-family: "Urbanist", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #f9fafc;
  font-size: 62.5%;
  overflow-x: hidden;
  scrollbar-width: thin;
}

/* #app::-webkit-scrollbar {
  width: 20px;
  background-image: linear-gradient(180deg, #d0368a 0%, #708ad4 99%);
}

#app::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

#app::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-image: linear-gradient(180deg, #d0368a 0%, #708ad4 99%);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
} */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: none;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(
    40deg,
    #ff8a8a 20%,
    #f771d1 40%,
    #a18aff 60%,
    #4cd2ff 80%,
    #6afc82 100%
  );
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    40deg,
    #ff5757 20%,
    #ff61d0 40%,
    #9278fa 60%,
    #24c5ff 80%,
    #43f761 100%
  );
}
::-webkit-scrollbar-track {
  background: none;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}

a {
  text-decoration: none; /* no underline */
}

.bourdain {
  z-index: 5;
}

.bourdain::before {
  background-color: rgba(10, 0, 95, 0.1);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}
