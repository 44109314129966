.table-row {
  background-color: #07001c;
}
.hashes > span {
  font-size: 0.6rem;
  font-weight: bold;
}

.concat-hash {
  resize: none;
}
