:root {
  --triangle-rotation-open: rotate(0);
  --triangle-rotation-close: rotate(540deg);
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: var(--triangle-rotation-open);
    transform: var(--triangle-rotation-open);
  }
  100% {
    -webkit-transform: var(--triangle-rotation-close);
    transform: var(--triangle-rotation-close);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: var(--triangle-rotation-open);
    transform: var(--triangle-rotation-open);
  }
  100% {
    -webkit-transform: var(--triangle-rotation-close);
    transform: var(--triangle-rotation-close);
  }
}
@-webkit-keyframes back-rotate-center {
  0% {
    -webkit-transform: var(--triangle-rotation-close);
    transform: var(--triangle-rotation-close);
  }
  100% {
    -webkit-transform: var(--triangle-rotation-open);
    transform: var(--triangle-rotation-open);
  }
}
@keyframes back-rotate-center {
  0% {
    -webkit-transform: var(--triangle-rotation-close);
    transform: var(--triangle-rotation-close);
  }
  100% {
    -webkit-transform: var(--triangle-rotation-open);
    transform: var(--triangle-rotation-open);
  }
}

@-webkit-keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

/* .list.open {
  -webkit-animation: slide-in-blurred-top 0.6s cubic-bezier(0.23, 1, 0.32, 1)
    both;
  animation: slide-in-blurred-top 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
}

.list.close {
  -webkit-animation: slide-in-blurred-top 0.6s cubic-bezier(0.23, 1, 0.32, 1)
    reverse both;
  animation: slide-in-blurred-top 0.6s cubic-bezier(0.23, 1, 0.32, 1) reverse
    both;
} */

.triangle.open {
  -webkit-animation: rotate-center 0.6s ease-in-out both;
  animation: rotate-center 0.6s ease-in-out both;
}

.triangle.close {
  -webkit-animation: back-rotate-center 0.6s ease-in-out both;
  animation: back-rotate-center 0.6s ease-in-out both;
}

.link-list-enter.link-list-enter-active {
  -webkit-animation: slide-in-blurred-top 0.6s cubic-bezier(0.23, 1, 0.32, 1)
    both;
  animation: slide-in-blurred-top 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
}

.link-list-exit.link-list-exit-active {
  -webkit-animation: slide-in-blurred-top 0.6s cubic-bezier(0.23, 1, 0.32, 1)
    reverse both;
  animation: slide-in-blurred-top 0.6s cubic-bezier(0.23, 1, 0.32, 1) reverse
    both;
}
