.panel-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.panel-enter.panel-enter-active {
  transition: 0.4s;
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.panel-exit {
  opacity: 1;
}

.panel-exit.panel-exit-active {
  transition: 0.4s;
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
